import { Link } from "gatsby"
import React from "react"
import LayoutAlt from "../components/LayoutAlt"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

export default function Home() {
  return (
    <LayoutAlt>
      <Seo
        title="South African Turnkey Rope Access Services"
        description="CAST Rope Access is a South African turnkey rope access service
              provider to the Renewables, Oil and Gas, Construction and Marine
              sectors."
      />
      <section className="hero hero--home px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../images/hero-home-v2.jpg"
          alt="Asset Lifecycle Rope Access Services"
          placeholder="blurred"
        />
        <div className="container mx-auto relative z-20">
          <div className="hero__content text-center">
            <h1 className="text-white mx-auto mb-6">
              Asset Lifecycle{" "}
              <span className="block">Rope Access Services</span>
            </h1>
            <p className="intro text-white lg:text-lg font-semibold tracking-wider normal-case mx-auto mb-8 lg:w-1/2">
              CAST Rope Access is a South African turnkey rope access service
              provider to the Renewables, Oil and Gas, Construction and Marine
              sectors.
            </p>
            <Link className="btn" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section id="capabilities" className="capabilities-home px-4 py-24">
        <h2 className="text-brand-blue text-center mb-12">Capabilities</h2>
        <div className="container mx-auto md:grid md:grid-cols-3 md:gap-8">
          <div className="capabilities-home__item capabilities-home__item--blue text-center  mb-12 md:mb-0">
            <Link to="/capabilities/renewables">
              <StaticImage
                className="-mb-16 relative z-20 rounded-full w-60 border-8 border-capability-blue mx-auto shadow-lg transition transform hover:-translate-y-1 hover:shadow-2xl"
                src="../images/thumb-renewables.jpg"
                alt="Renewables"
                placeholder="blurred"
              />
            </Link>
            <div className="capabilities-home__item__content relative pt-24 pb-12 px-4">
              <div className="capabilities-home__item__content__inner capabilities-home__item__content__inner--renewables absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"></div>
              <h3 className="relative z-40 mb-6 text-capability-blue">
                <Link to="/capabilities/renewables">Renewables</Link>
              </h3>
              <ul className="relative z-40 mb-6 px-4 lg:px-0">
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/renewables">
                    Cable Pull-In, Post Installation Testing – IR, TDR & OTDR
                  </Link>
                  <span className="border-b-2 border-capability-blue inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/renewables">
                    Wind Turbine Inspections Repairs &amp; Maintenance
                  </Link>
                  <span className="border-b-2 border-capability-blue inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/renewables">Temporary Platform</Link>
                  <span className="border-b-2 border-capability-blue inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/renewables">
                    Wind Farms - Green Fields &amp; Construction Phase
                  </Link>
                </li>
              </ul>
              <Link
                to="/capabilities/renewables"
                className="relative z-40 font-semibold text-capability-blue uppercase tracking-widest inline-block mx-auto hover:underline"
              >
                Capability Details
              </Link>
            </div>
          </div>

          <div className="capabilities-home__item capabilities-home__item--green text-center mb-12 md:mb-0">
            <Link href="/capabilities/oil-and-gas">
              <StaticImage
                className="-mb-16 relative z-20 rounded-full w-60 border-8 border-capability-green mx-auto shadow-lg transition transform hover:-translate-y-1 hover:shadow-2xl"
                src="../images/thumb-oil-and-gas.jpg"
                alt="Oil and Gas"
                placeholder="blurred"
              />
            </Link>
            <div className="capabilities-home__item__content relative pt-24 pb-12 px-4">
              <div className="capabilities-home__item__content__inner capabilities-home__item__content__inner--oil-and-gas absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"></div>
              <h3 className="mb-6 text-capability-green relative z-40">
                <Link to="/capabilities/oil-and-gas">Oil &amp; Gas</Link>
              </h3>
              <ul className="mb-6 px-4 relative z-40 lg:px-0">
                <li className="relative pb-3 mb-3">
                  <Link href="/capabilities/oil-and-gas">
                    Near Shore Inspections &amp; Maintenance
                  </Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">
                    Flare Inspection Maintenance &amp; Repairs
                  </Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">
                    Chimney Inspection & Repairs
                  </Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">Storage Tanks</Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">
                    Offshore Pipelines Inspections, Repairs &amp; Maintenance
                  </Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">
                    FPSO Pipelines Inspections, Repairs &amp; Maintenance
                  </Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">
                    Offshore Installation
                  </Link>
                  <span className="border-b-2 border-capability-green inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/oil-and-gas">
                    Jack Up Repairs &amp; Maintenance
                  </Link>
                </li>
              </ul>
              <Link
                to="/capabilities/oil-and-gas"
                className="relative z-40 font-semibold text-capability-green uppercase tracking-widest inline-block mx-auto hover:underline"
              >
                Capability Details
              </Link>
            </div>
          </div>

          <div className="capabilities-home__item capabilities-home__item--purple text-center">
            <Link to="/capabilities/asset-integrity">
              <StaticImage
                className="-mb-16 relative z-20 rounded-full w-60 border-8 border-capability-purple mx-auto shadow-lg transition transform hover:-translate-y-1 hover:shadow-2xl"
                src="../images/thumb-asset-integrity.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
            </Link>
            <div className="capabilities-home__item__content relative pt-24 pb-12 px-4">
              <div className="capabilities-home__item__content__inner capabilities-home__item__content__inner--asset-integrity absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"></div>
              <h3 className="mb-6 text-capability-purple relative z-40">
                <Link to="/capabilities/asset-integrity">Asset Integrity</Link>
              </h3>
              <ul className="mb-6 px-4 lg:px-0 relative z-40">
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/asset-integrity">
                    RTG, STS & Bulk Loaders
                  </Link>
                  <span className="border-b-2 border-capability-purple inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/asset-integrity">
                    Pipe Rack Inspection &amp; Repairs
                  </Link>
                  <span className="border-b-2 border-capability-purple inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
                </li>
                <li className="relative pb-3 mb-3">
                  <Link to="/capabilities/asset-integrity">
                    Wharf &amp; Jetty Repairs
                  </Link>
                </li>
              </ul>
              <Link
                to="/capabilities/asset-integrity"
                className="font-semibold text-capability-purple uppercase tracking-widest inline-block mx-auto relative z-40 hover:underline"
              >
                Capability Details
              </Link>
            </div>
          </div>
        </div>
      </section>
    </LayoutAlt>
  )
}
